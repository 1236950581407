<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物申领</h3>
    <div class="detail">
      <h4 class="title">动物信息</h4>
      <div class="desc">
        <span>动物品系：{{ activeInfo.strain }}</span>
        <span v-if="activeInfo.isGene==1">基因类型：{{ activeInfo.geneKeys }}</span>
        <span>清洁等级：{{ activeInfo.grade == 0 ? '普通级' : activeInfo.grade == 1 ? 'SPF' : '清洁级' }}</span>
        <span v-if="activeInfo.aniSupply">来源：{{ activeInfo.aniSupply.supplier }}</span>
      </div>
      <h4 class="title">动物申领</h4>
      <div class="desc">
        <span>笼舍申领数量：{{ activeInfo.applyCageNum }}</span>
      </div>
      <el-table
          border
          :data="activeInfo.aniOrderDetailList"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          max-height="400px">
        <el-table-column label="基因类型" v-if="activeInfo.isGene==1">
          <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
            <template slot-scope="scope">
              {{ scope.row.typeList[ind].type }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="性别"
            show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.gender == 0 ? '♀' : '♂' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="realNum"
            label="数量" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="weight"
            label="体重(g)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="weekDays"
            label="周龄" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <template v-if="activeInfo.isLabAni===1">
        <h4 class="title" style="margin-top: 14px;">动物信息(请填写真实有效的数据)</h4>
        <el-table
            :data="qualified" border
            class="record-table"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            style="font-size:14px;color:#333">
          <el-table-column label="基因类型" v-if="activeInfo.isGene==1">
            <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
              <template slot-scope="scope">
                {{ scope.row.typeList[ind].type }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
              label="性别"
              show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.gender == 0 ? '♀' : '♂' }}
            </template>
          </el-table-column>
          <el-table-column label="耳号" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" :disabled="diasabled" placeholder="耳号" v-model="scope.row.overbit"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="出生日期" width="180">
            <template slot-scope="scope">
              <el-date-picker :disabled="diasabled"
                              style="width: 140px"
                              size="small" :picker-options="pickerOptions"
                              @change="selectBir($event,scope.row,scope.$index)"
                              v-model="scope.row.birthDate"
                              type="date"
                              placeholder="出生日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="周龄" width="90" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.weekDays ? scope.row.weekDays : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150" v-if="state!=1">
            <template slot-scope="scope">
              <div class="editColor">
                <el-button type="text" @click="copy(scope.row,scope.$index,1)">复制</el-button>
                <el-button type="text" @click="deleteA(scope.row,scope.$index,1)">删除</el-button>
                <el-button type="text" v-if="state==0" @click="save(scope.row,scope.$index)">保存</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-if="state!=1">
        <el-form ref="addForm" size="small" :model="claims" class="addForm" label-suffix=":"
                 label-width="80px">
          <h3 class="tit">备注信息</h3>
          <el-form-item label="备注信息" prop="remark">
            <el-input v-model="claims.remark" clearable placeholder="请输入备注信息" type="textarea" rows="4"></el-input>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="submitBtn('addForm',1)">提交申领</el-button>
            <el-button plain type="primary" size="small" @click="submitBtn('addForm',0)">保存</el-button>
          </div>
        </el-form>
      </template>
      <template v-else>
        <div class="desc" style="margin-top: 16px">
          <span>备注信息：{{ claims.remark }}</span>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import moment from "moment";

export default {
  name: "claims",
  props: ['id', 'activeInfo', 'status'],
  inject: ['reload'],
  data() {
    return {
      // activeInfo: {},
      claims: {
        remark: "",
      },
      state: null,
      claimsId: null,
      typeList: [],
      qualified: [],
      diasabled: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
    }
  },
  mounted() {
    if (this.status == 15) {
      this.getType()
    } else {
      this.getApply()
    }

  },
  methods: {
    getType() {
      let that = this;
      that.$get("/subject/order/to/" + this.id).then(res => {
        if (res.status == 200) {
          let arrUn = []
          this.activeInfo.aniOrderDetailList.forEach(obj => {
            obj.typeList = JSON.parse(obj.gene)
            let a = {
              typeList: obj.typeList,
              gender: obj.gender,
              gene: obj.gene,
              overbit: "",
              birthDate: "",
              weekDays: "",
              orderDetailId: obj.id
            }
            arrUn.push(this.deepClone4(a))
          })
          this.qualified = arrUn
// 赋值typeList
          this.getTypeList()
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    // 获取申领中的
    getApply() {
      let that = this
      that.$get("/ani/apply/" + this.id).then(res => {
        if (res.status == 200) {
          // 申领状态
          this.state = res.data.state

          this.activeInfo.aniOrderDetailList.forEach(obj => {
            obj.typeList = JSON.parse(obj.gene)
          })

          if (this.state == 1) {
            this.diasabled = true
          }
          // 申领id
          this.claimsId = res.data.id
          // 备注
          this.claims.remark = res.data.remark

          if (this.activeInfo.isLabAni == 1) {
            let arrUn = []
            res.data.aniQuarResultList.forEach(obj => {
              obj.typeList = JSON.parse(obj.gene)
              // obj.birthDate = moment(obj.birthDate).format()
              this.selectBir(obj.birthDate, obj)
              arrUn.push(this.deepClone4(obj))
            })
            this.qualified = arrUn
          }

          // 赋值typeList
          this.getTypeList()
        }
      })
    },
    // 获取基因类型
    getTypeList() {
      let arr = this.activeInfo.geneKeys.split(',')
      arr.forEach(item => {
        this.typeList.push({
          title: item
        })
      })
    },
    deepClone4(target, map = new Map()) {
      // 检测数据类型
      if (typeof target === 'object' && target !== null) {
        // 克隆数据之前，判断之前是否克隆过
        let cache = map.get(target);
        if (cache) {
          return cache;
        }
        // 创建一个容器
        const isArray = Array.isArray(target)
        const result = isArray ? [] : {};
        // 将新结果存入到容器中
        map.set(target, result);
        // 正则判断
        if (target.constructor === RegExp) {
          return target;
        } else if (isArray) {
          // 遍历数组
          target.forEach((item, index) => {
            result[index] = this.deepClone4(item, map);
          });
        } else {// 对象
          Object.keys(target).forEach(key => {
            result[key] = this.deepClone4(target[key], map);
          });
        }
        return result;
      } else {
        return target;
      }
    },
    // 选择出生日期 改变周龄
    selectBir(value, row, index) {
      if (value) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        let birDay = moment(value).format("YYYY-MM-DD");
        let diffTime = moment(today).diff(moment(birDay), 'days');
        let weeksAge = diffTime / 7
        row.weekDays = Math.round(weeksAge)
      }
    },
    // 复制
    copy(val, index, ind) {
      if (val.id) {
        this.$delete(val, 'id')
        this.$postJson('/ani/quarResult', val).then(res => {
          this.$message.success("新增成功")
          this.reload()
        })
      }
      this.qualified.splice(index, 0, JSON.parse(JSON.stringify(val)))
    },
    // 删除
    deleteA(row, index, ind) {
      if (row.id) {
        this.$del('/ani/quarResult', {id: row.id}).then(res => {
          this.$message.success("删除成功")
        })
      }
      this.qualified.splice(index, 1)
    },
    // 保存
    save(row) {
      let vm = this
      let obj = {
        id: row.id,
        overbit: row.overbit,
        birthDate: moment(row.birthDate).format("yyyy-MM-DD HH:mm:ss")
      }
      vm.$putJson('/ani/quarResult', obj).then(res => {
        this.$message.success("修改成功")
      })
    },
    submitBtn(task, state) {
      this.$refs[task].validate((valid) => {
        if (valid) {
          let data = {
            state: state,
            remark: this.claims.remark,
            subId: this.activeInfo.subId,
            isProd: 1,
          }
          let url;
          if (this.state == 0) {
            this.$set(data, "id", this.claimsId)
            url = this.$put('/ani/apply', data)
          } else {
            if (this.activeInfo.isLabAni === 1) {
              this.qualified.forEach(item => {
                item.birthDate = moment(item.birthDate).format("yyyy-MM-DD HH:mm:ss")
              })
              this.$set(data, "aniQuarResultList", this.qualified)
            }
            url = this.$postJson('/ani/apply', data)
          }
          url.then(res => {
            this.$message.success("申领成功")
            this.$router.go(-1)
          }).catch(err => {
            this.$message.error("申领失败")
          })
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

.addForm {
  margin-top: 16px;

  .el-form-item:nth-child(2) {
    margin-bottom: 10px;
  }
}
</style>
